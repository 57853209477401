.Film-Detail {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;

    max-height: 75vh;
}

.return-holder{
    grid-row: 3;
    grid-column: 1;
}
.Title-Card {
    grid-row:  1/3;
    padding: 2em;
    max-width: 300px;
    max-height: 75vh;
}

.Film-Data-Card{
    margin-top: 1em;
    padding: 2em;
    background-color: var(--accent-dark);
    border-radius: 10px;
    
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 75vh;
    min-height: 50vh;
    min-width: 30vw;
}
.favorites-button{
    margin-top: 2em;
    margin-bottom: 2em;
}
.Release-Stub p{
    margin-left: 2em;
    margin-bottom: 1em;
}

.Star-Rating{
    margin-left: 2em;
    margin-bottom: 1em;
}

.Overview-Stub p{
    margin-left: 2em;
    margin-bottom: 1em;
}

.stub-info{
    display: inline;
}
.stub-info + .stub-info::before {
/*Credit to Stack Overflow user Ori Drori https://stackoverflow.com/questions/47881767/*/
    display: inline-block;
    white-space: pre;
    content: ", ";
}

.Person-List {
    overflow-y: scroll;
    max-height: 50vh;
    padding-left: 0.5em;
}

.Cast-Item, .Crew-Item {
    display: flex;
}

.t-svg{
     height: 80px;
     width: 60px;
     margin-right: 1em;
}
.i-svg{
    height: 70px;
    width: 124px;
}