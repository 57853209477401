.movie-list {
    grid-column: 2/3;
    display: grid;
    grid-template-columns: 105px 1fr 1fr 1fr 115px;
    grid-template-rows: auto auto 1fr;
    height: 70vh;
    background-color: var(--accent-dark);
    border:2px solid var(--accent-light);
    margin: 5px;

    overflow-x: scroll;
    overflow-y: hidden;
  }
  
  .list-heading {
    grid-column: 1 / span 6;
    text-align: center;
    margin-top: 0.5em;
    margin-bottom: unset;
  }
  
  .movies {
    padding: 0;
    margin: 0;
    grid-column: 1 / span 6;

    overflow-y: scroll;
    overflow-x: hidden;
  }
  
  .movie-list-item {
    display: grid;
    grid-template-columns: auto 1fr 1fr 1fr auto auto;
    grid-gap: 10px;
    padding: 5px;
  }
  .movie-list-item p{
    font-size: 1.3em;
    text-decoration: none;
  }

  .movie-list-item a{
    text-decoration: none;
  }
  
  .movie-title {
    grid-column: 2 / 3;
  }

  .loading {
    grid-column: 3 / 3;
    grid-row: 3;
    justify-self: center;
  }

  .empty-search {
    grid-column: 3 / 3;
    justify-self: center;
  }

  div.between {
    display: inherit;
  }

  h3 button {
    color: white;
    background-color: transparent;
    font-size: 1em;
    font-weight: bold;
    border: none;
    cursor: pointer;
  }

  h3 button:hover {
    background-color: transparent;
  }


  .favorite-btn{
    padding: 10px;
    transition: border 2s ease-in;
  }

  .favorite-btn svg:hover {
    cursor: pointer;
    color: red;
  }
  
  