.favorites-bar{
    background: var(--accent-dark);
    border-color: var(--accent-light);
    border: thick;
  }
  .favorites {
    display: grid;
    grid-template-columns: repeat(15, 1fr);
    grid-auto-flow: column;
  
    align-items: center;
    grid-gap: 10px;
    
    color: var(--header-back);
  
    font-size: 0.8em;
    font-weight: bold;
  }
  
  .favorite {
    position: relative;
    justify-self: end;
  }
  .favorite > button {
    opacity: 0%;
  }
  
  .favorite:hover button {
    opacity: 80%;
  }
  
  .closeButton {
    position: absolute;
    top: 0;
    right: 0;
    background-color: grey;
    border: none;
    outline: none;
    height: 25%;
    cursor: pointer;
  }
  

  .favorites-bar {
    display: inline-flex;
    overflow-x: scroll;
    grid-column: 1 / 4;
  }
  
  .favorites-bar h3, .favorites-bar div{
    margin: 1em;
  }
  .favorites-bar div{
    cursor: pointer;
  }

  /*Classes for the transition of showing and hiding the favorites list. 
      ALso used when delting and adding favorite items*/
      
  .display-enter img{
    opacity: 0;
    height: 0px;
   }
  .display-enter-active img {
    opacity: 1;
    height: 138px;
    transition: all 300ms ease-in;
  
  }

  .display-exit img{
    opacity: 1;
    height: 130px;
   }
  .display-exit-active img {
    opacity: 0;
    height: 0px;
    transition: all 300ms ease-in;

  
  }

  
  