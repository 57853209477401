
:root {
  --background: #504b43;
  --accent: #8fd694;
  --accent-dark: #397367;
  --focus: #52489c;
  --accent-light: #5da399;
  --text:#fefefa;

}

body {
  margin: 0;
  
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background);
  color: #fefefa;
}
ul {
  list-style-type: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  color: white;
}
.home-view{
  
  font-weight: bold;
  text-align: center;
  min-height: 100vh;
  position: relative;
}

.home-view div h1 {
  font-size: 2rem;
  text-shadow: 2px 2px black;
}


.home-view p{
  position: fixed;
  width: 100%;
  bottom: 0%;
  margin: 20px;
}


.Detail-View {
  display: grid;
  margin-left: 1em;
  margin-right: 1em;
}

.movie-browser {
  background-color: var(--background);
  padding: 25px;
  border-radius: 10px;
  display: inline-block;
  margin-top: 200px;
}


.default-view {
  display: grid;
  grid-template-rows: auto auto 2fr;
  grid-template-columns: auto minmax(auto, 1fr);
  margin-left: 1em;
  margin-right: 1em;
}

.header {
  grid-column: 1 / 4;
  background-color: var(--background);
}
.header-menu {
  float: right;
  
}
.header-title {
  float: left;
}

.header-menu button {
  background-color: var(--accent);
  border-radius: 2px;
  text-emphasis: bold;
  margin: 2em;
  padding: 5px;
  border: none;
  
}

.header-menu button:hover {
  background-color: var(--focus);

}

.right-align {
  text-align: right;
}

.center-align {
  text-align: center;
}

.left-align {
  text-align: left;
}

.logo {
  height: 100px;
  min-height: 10px;
}



button {
  background-color: var(--accent);
  border: none;
  font-weight: bold;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
  outline: none;
  border-radius: 2px;
  color: var(--background);
}



button:hover{
  background-color: var(--focus);
  color: var(--text);
}

/*Classes for animating the homepage entering and exiting*/
.curtain-call{
  z-index: 10;
    position: absolute;
    top: -974px;
    left: 0;
    width: 100%;
    height: 100%;
}
.home-page-enter{
  opacity: 0;

}
.home-page-enter-active{
  opacity: 1;
  transition: opacity 500ms ease-out;
}

.home-page-exit{
  opacity: 1;

}
.home-page-exit-active{
  opacity: 0.5;
  transition: opacity 700ms ease-out;
}




.home-page-exit .curtain-call{
  top: -1000px;
}

.home-page-exit-active .curtain-call{
  top:0px;
  transition: all 700ms ease-out;
}

.home-page-enter .curtain-call{
  top: 0px;
}

.home-page-enter-active .curtain-call{
  top:-1000px;
  transition: all 700ms ease-out;
}


.modal-dialog {
  background-color: var(--accent-dark);
  padding: 2em;
  margin: 2em;
  border: 2px solid var(--accent-light);
  border-radius: 10px;
}

.modal-lightbox {
  background-color: var(--accent-dark);
  padding: 2em;
  margin: 2em;
  border: 2px solid var(--accent-light);
  border-radius: 10px;
  width: fit-content;
}

