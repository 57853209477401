.filter{
    display: flex;
}

.hide-enter {
    opacity: 0;
    width: 0%;

}
.hide-enter-active {
    opacity: 1;
    width: 100%;
    
    transition: all 300ms ease;
}

legend{
    font-size: 2em;
    font-weight: bold;
    background-color: var(--accent-dark);
    border-top: 2px solid var(--accent-light);
    border-left: 2px solid var(--accent-light);
    border-right: 2px solid var(--accent-light);
    border-radius: 3px;
    margin: 5px;
    padding-left: 10px;
    padding-right: 10px;
}
.hide-exit {
    opacity: 1;
    width: 100%;
}

.hide-exit-active {
    opacity: 0;
    width:0%;

    transition: all 300ms ease-out;
}

.filter-label  {
    display: grid;
    grid-template-columns: repeat(4, auto);
    
}
.filter-label label {
    line-height: 2em;
    grid-column: 1/4;
}
.filter-heading {
    font-weight: bold;
    grid-column: 1/4;
  }

#box{
    min-width: 0;
    max-width: 450px;
    background-color: var(--accent-dark);
    border:2px solid var(--accent-light);
}

input[type=number]{
    appearance: textfield;
}
input[type=range]{
    width: 50%;
    background-color: transparent;
    cursor: grab;
}

input[type=range]:active{
    cursor: grabbing;
}

input[type=range]:disabled{
    cursor: not-allowed;
}

input{
    margin-left: 2em;
}

.input, .filter-label label span {
    float: right;
    margin-right: 10px;
    line-height: 100%;
}

